body {
  margin: 0;
  padding: 0;
  /* background-color: #ffcc00; */
  background-color: transparent;
}

html, body {
    height: 100%;
}
